<template>
	<div class="about">
		<template v-for="proj in projects">
			<div class="list-group">
				<div class="list-group-item active" @click="proj.show = !proj.show">
					<h4 class="list-group-item-heading">
						{{proj.name}}
						<span v-if="proj.show" v-html="uparrow"></span>
						<span v-else v-html="downarrow"></span>
					</h4>
				</div>
				<div class="list-group-item" v-if="proj.show">
					<h4 class="list-group-item-heading">
						简介
					</h4>
					<p class="list-group-item-text">
						{{proj.introduction}}
					</p>
				</div>
				<div class="list-group-item" v-if="proj.show">
					<h4 class="list-group-item-heading">
						演示地址
					</h4>
					<p>
						<a v-if="proj.open" :href="proj.url" target="_blank" class="list-group-item-text">
							{{proj.url}}
						</a>
						<span v-else>{{proj.url}}</span>
					</p>
				</div>
			</div>
		</template>
	</div>
</template>

<script>
	export default {
		name: "Demo",
		data() {
			return {
				projects: [{
					name: "图片水印",
					introduction: "可以为图片添加文字或图片水印",
					url: "https://www.wenruitao.top/watermarkvue",
					open: true,
					show: false
				}, {
					name: "简单的门户网站及管理端",
					introduction: "一个简单的门户网站模板，附带新闻管理后台",
					url: "https://www.wenruitao.top/sps/",
					open: true,
					show: false
				}, {
					name: "生成数据库设计文档",
					introduction: "通过读取数据库表格信息，生成数据库设计文档（word格式）",
					url: "https://www.wenruitao.top/pddvue/",
					open: true,
					show: false
				}],
				uparrow: "&#9650",
				downarrow: "&#9660"
			}
		},
	};
</script>

<style lang="scss" scoped="scoped">
	*:before,
	*:after {
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
	}

	a {
		text-decoration: none;
	}

	.about {
		height: 24rem;
	}

	.list-group {
		padding-left: 0;
		margin-bottom: 10px;
		text-align: left;
		.list-group-item {
			position: relative;
			display: block;
			padding: 10px 10px;
			margin-bottom: -1px;
			background-color: #fff;
			border: 1px solid #ddd;
			&.active,
			&.active:focus,
			&.active:hover {
				z-index: 2;
				color: #fff;
				background-color: #80bc5d;
				border-color: #80bc5d;
			}
			&:first-child{
				border-top-left-radius: 4px;
				border-top-right-radius: 4px;
			}
			& p a:hover {
				font-size: large;
			}
			
			.list-group-item-heading {
				margin-top: 0;
				margin-bottom: 5px;
				& span {
					float: right;
				}
			}
		}
	}
</style>
